import { supabase } from "./supabase";

export const fetchAvailable = async () => {
  const { data, error } = await supabase
    .from("available")
    .select()
    .or("kind.eq.available,kind.is.null");
  if (error) {
    throw error;
  }
  return data;
};

export const fetchBooked = async () => {
  const { data, error } = await supabase
    .from("available")
    .select()
    .eq("kind", "booked");
  if (error) {
    throw error;
  }
  return data;
};

export const insertBooked = async ({ date, start, end }) => {
  const { data, error } = await supabase
    .from("available")
    .insert({ date, start, end, kind: "booked" });
  if (error) {
    throw error;
  }
  return data;
};
