import React, { useEffect, useState } from "react";
import "./Calendar.css"; // Optionally, you can style your calendar using CSS
import { fetchAvailable, fetchBooked, insertBooked } from "./data";
import Popup from "./Popup";

function removeDuplicates(sortedArray) {
  if (sortedArray.length === 0) {
    return [];
  }

  let uniqueArray = [sortedArray[0]]; // Initialize with the first element

  for (let i = 1; i < sortedArray.length; i++) {
    // Compare current element with the last element in uniqueArray
    if (sortedArray[i] !== uniqueArray[uniqueArray.length - 1]) {
      uniqueArray.push(sortedArray[i]);
    }
  }

  return uniqueArray;
}

const Calendar = () => {
  const [availableTimes, setAvailableTimes] = useState([]);
  const [bookedTimes, setBookedTimes] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  useEffect(() => {
    async function fetchMy() {
      const a = await fetchAvailable();
      const b = await fetchBooked();
      setAvailableTimes(a);
      setBookedTimes(b);
    }
    fetchMy();
  }, []);

  // State to manage the selected time for each day
  const [selectedTimes, setSelectedTimes] = useState({});

  // Function to handle click on a time slot
  const handleTimeClick = (date, time) => {
    setSelectedTimes({
      [date]: time,
    });
    // Handle further actions on time click if needed
    console.log("Selected time for", date, ":", time);
  };

  const handleSubmitClick = () => {
    for (const date in selectedTimes) {
      const time = selectedTimes[date];
      insertBooked({ date, start: time, end: time + 1 });
      setShowPopup(true);
      break;
    }
  };

  // Function to render the time slots for a specific day
  const renderTimeSlots = (date) => {
    const timeSlots = [];
    const dayAvailableTimes = availableTimes.filter((day) => day.date === date);

    // Generate time slots from 0 to 23 hours
    for (let hour = 0; hour < 24; hour++) {
      let isAvailable = false;
      let isBooked = false;
      // Check if the current hour is within any available time range for the current day
      for (const { start, end } of dayAvailableTimes) {
        if (hour >= start && hour < end) {
          isAvailable = true;
          break;
        }
      }
      // Check if already booked
      if (isAvailable) {
        for (const { start, end } of bookedTimes.filter(
          (x) => x.date === date
        )) {
          if (hour >= start && hour < end) {
            isAvailable = false;
            isBooked = true;
          }
        }
      }
      // Define the className based on availability and selection
      let slotClassName = isAvailable ? "time-slot available" : "time-slot";
      if (isBooked) {
        slotClassName += " booked";
      }
      if (selectedTimes[date] === hour) {
        slotClassName += " selected";
      }

      // Push JSX for each time slot
      let handleOnClick = () => {};
      if (isAvailable) {
        handleOnClick = () => handleTimeClick(date, hour);
      }
      timeSlots.push(
        <div key={hour} className={slotClassName} onClick={handleOnClick}>
          {hour}:00 - {hour + 1}:00
        </div>
      );
    }
    return timeSlots;
  };

  // Function to render each day's time slots
  const renderDays = () => {
    const allDates = availableTimes.map((x) => x.date).sort();
    const dates = removeDuplicates(allDates);
    return dates.map((day) => (
      <div key={day} className="day">
        <h3>{day}</h3>
        <div className="time-slots">{renderTimeSlots(day)}</div>
      </div>
    ));
  };

  const renderSubmit = () => {
    if (
      selectedTimes === undefined ||
      Object.keys(selectedTimes).length === 0
    ) {
      return null;
    }

    return (
      <div className="selected-times">
        {Object.keys(selectedTimes).map((date) => (
          <p key={date}>
            Selected Time for {date}: {selectedTimes[date]}:00 -{" "}
            {selectedTimes[date] + 1}:00
          </p>
        ))}
        <button className="btn btn-primary" onClick={handleSubmitClick}>
          Submit
        </button>
      </div>
    );
  };

  return (
    <div className="calendar">
      <h2 className="title">Available Times</h2>
      <div className="alert alert-secondary">
        Times are in Pacific Daylight Saving.
      </div>
      <div className="days">{renderDays()}</div>
      {renderSubmit()}
      {showPopup && (
        <Popup
          message={"Your time has been scheduled"}
          onClose={() => setShowPopup(false)}
        />
      )}
    </div>
  );
};

export default Calendar;
